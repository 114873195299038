<template>
  <section class="directflightsonly_area mobail">
    <div class="container">
      <a href="#" class="btn-return"  @click="() => gotoSearchPage()">
        <span><i class="fa-solid fa-chevron-right"></i></span>{{$t("sabre.change-flight.return-to-result")}}
      </a>
      <div class="changeflights_title">
        <h2>
          {{ $t("sabre.change-flight.where-flight-back", {dest: arrivalCityName, day: departureDate, time: departureTime, dayName: departureDayName})}}
        </h2>
      </div>

      <mobile-one-flight-item kind="changeFlight" :data="changingItem.legs[0]" v-if="changingItem"/>

      <div class="changeflights_titlebottom">
        <h2>
          {{ $t("sabre.change-flight.look-for-flight-from-where", {where: arrivalCityName, day: arrivalDate, dayName: arrivalDayName}) }}
        </h2>
        <small>{{$t("sabre.change-flight.note-message")}}</small>
      </div>
    </div>

    <b-modal
      id="detailFlightMobileModal"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal detailpuppyModal modal-dialog-centered"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>
      </template>

      <div class="flightdetails_innerbox text-right">
        <h4>{{$t("sabre.search-result.box.flight-details")}}</h4>
        <div class="flightdetails_item" v-if="changingItem">

          <div v-for="(segment, inx) in changingItem.legs[0].segmentExcerpts" :key="inx">
            <collapse-one-item :data="segment" :department="changingItem.legs[0].excerpt.cabinCode" :checkinBaggage="changingItem.legs[0].excerpt.checkinBaggageAllowance"/>
            <h3 v-if="inx !== changingItem.legs[0].excerpt.connections">
              <strong>{{$t("sabre.search-result.box.stop-in", {dest: getDestCityName(segment.arrival.city)})}}</strong>
              {{$t("sabre.search-result.box.flight-hour-minute", getElapsedTime(segment.elapsedTime))}}
            </h3>
          </div>

        </div>
      </div>

    </b-modal>
  </section>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import changeFlightTopItem from './returnFlightChangeMixin';

export default {
  name: 'change-flight-panel-mobile',
  mixins: [changeFlightTopItem],
  components: {
    BModal,
    MobileOneFlightItem: () => import('../searchResultBoxMobile/mobileBoxAtom/mobileOneFlightItem'),
    CollapseOneItem: () => import('../searchResultBox/searchResultBoxAtom/collapseOneItem'),
  },
};
</script>
